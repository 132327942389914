import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "moment/locale/sv"

const JobsTemplate = ({ data: { jobs }, pageContext }) => {
  return (
    <Layout
      className="jobs"
      language={pageContext.language}
      template={pageContext.template}
    >
      <Seo title={jobs.title} template={pageContext.template} seo={jobs.seo} />
      <section className="content text-center">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div claclassNamess="col-12 col-md-10 col-lg-8">
              <div className="row">
                <div className="col-12 subTitle">
                  <h4>Jobb</h4>
                </div>
                <div className="col-12 title" itemProp="name">
                  <h1>{parse(jobs.title)}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content text-left">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="row">
                <div className="col-12 main-content columns-1">
                  {parse(jobs.jobData.text)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default JobsTemplate

export const pageQuery = graphql`
  query jobs($id: String!) {
    jobs: wpJob(id: { eq: $id }) {
      id
      title
      uri
      slug
      date
      jobData {
        text
      }
      seo {
        canonical
        metaDesc
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          localFile {
            publicURL
          }
        }
        schema {
          pageType
          raw
        }
      }
    }
  }
`
